import React, { useState } from 'react';
import Form from './Form';
import { FIELD_TYPES, DATA_MODELS } from './Form/Field';
import ThankYou from './ThankYou';

const FORM_ID = 'ask_a_question';
const PIPELINE_ID = 29;

const formConfig = [
  {
    label: 'First Name',
    name: 'First Name',
    isRequired: true,
    model: DATA_MODELS.person
  },
  {
    label: 'Last Name',
    name: 'Last Name',
    isRequired: true,
    model: DATA_MODELS.person
  },
  {
    label: 'Email',
    name: 'Email',
    isRequired: true,
    model: DATA_MODELS.person
  },
  {
    label: 'Message',
    type: FIELD_TYPES.textarea,
    isRequired: true,
    isNote: true,
  },
];

const AskAQuestion = () => {
  const [submitted, setSubmited] = useState(false);
  const submit = () => {
    setSubmited(true);
  };

  if (submitted) {
    return <ThankYou />;
  }

  return (
    <Form
      headline="Ask Us A Question - We Love To Chat!"
      formId={FORM_ID}
      pipelineId={PIPELINE_ID}
      config={formConfig}
      initValues={null}
      onSubmit={submit}
    />
  );
};

export default AskAQuestion;